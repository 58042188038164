import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../../components/Layout';
import ProseModule from '../../components/ProseModule';
import SegmentedControl from '../../components/SegmentedControl';
import SimpleHeaderModule from '../../components/SimpleHeaderModule';

export default () => (
  <Layout
    pageTitle="Miter for Zoom - Documentation"
    description="Documentation on how to use Miter inside of a Zoom meeting"
  >
    <SimpleHeaderModule title="Miter for Zoom: Docs">
      <SegmentedControl
        selectedOptionName="users"
        options={[
          {name: 'users', label: 'Users', destination: '/zoom-docs'},
          {name: 'admins', label: 'Zoom Admins', destination: '/zoom-docs/admin-guide'},
        ]}
      />
    </SimpleHeaderModule>
    <ProseModule>
      <h2>Installation</h2>
      <ol>
        <li>
          Launch Zoom and ensure your Zoom application is up to date. Do this{' '}
          <strong>even if you've enabled auto-update:</strong>:
          <ul>
            <strong>Windows:</strong> Click your profile picture in the upper right of Zoom's main window and select{' '}
            <strong>Check for Updates.</strong>
          </ul>
          <ul>
            <strong>Mac:</strong> Open the <strong>zoom.us menu</strong> at the top left of your screen and select
            <strong>Check for Updates</strong>.
          </ul>
        </li>
        <li>
          <a href="https://marketplace.zoom.us/apps/4gAucbSMSAi4qaaQPmXatA" target="_blank">
            <strong>Go to Miter's Zoom App</strong>
          </a>{' '}
          in Zoom's App Marketplace.
        </li>
        <li>
          If you see a blue <strong>Sign In to Add</strong> button, click that and sign into Zoom.
        </li>
        <li>
          Depending on how your Zoom account is set up, you should see one of two things:
          <div className="Columns">
            <div className="ImageWithDescription">
              <StaticImage
                className="Shadowed"
                src="../../../images/zoom-docs/add-authorized.png"
                alt="Screenshot of Add button in Zoom App Marketplace"
              />
              <p>
                If you see <strong>Visit Site to Add,</strong> click it to begin installation. Then click{' '}
                <strong>Authorize</strong> at the bottom of the next screen. Once you do, Miter will be ready to use and
                you'll be prompted to open the Zoom application.
              </p>
            </div>
            <div className="ImageWithDescription">
              <StaticImage
                className="Shadowed"
                src="../../../images/zoom-docs/add-not-authorized.png"
                alt="Screenshot of Request Approval button in Zoom App Marketplace"
              />
              <p>
                If you see <strong>Request to Add,</strong> you'll need approval from your organization's Zoom
                administrator. Click the button, but note that your administrator will not be notified; you'll have to
                tell them To find your Zoom account owner, see "Account Owner" in your{' '}
                <a href="https://zoom.us/account">Zoom Account Profile.</a> (If your admin isn't sure what to do, refer
                them to our <Link to="/zoom-docs/admin-guide">Admin Guide.</Link>)
              </p>
            </div>
          </div>
        </li>
        <li>That's it! If you're already in a Zoom meeting, you should see Miter appear in your Zoom window.</li>
      </ol>
      <h2>Troubleshooting</h2>
      <h3>Zoom says I'm signed out and can't use apps.</h3>
      <ul>
        <li>
          <strong>
            Always ensure the Zoom application is <em>already open</em> on your computer <em>before</em> you join a
            meeting.
          </strong>{' '}
          Sometimes, if you open a Zoom meeting from a link (e.g., from your calendar or an email) and the Zoom
          application isn't already open, you'll join the meeting signed out and won't have access to your Zoom Apps. If
          this happens, simply quit Zoom, reopen it, <em>then</em> join your meeting.
        </li>
        <li>
          When you sign into Zoom, if you trust the computer you're using, check the{' '}
          <strong>"Keep me signed in"</strong> checkbox
        </li>
      </ul>
      <h3>When I try to install, I'm seeing a notification like this:</h3>
      <StaticImage src="../../../images/zoom-docs/preapproval-popup.png" alt="Popup 'needs preapproval' notification" />
      <p>
        Sometimes this notification appears when you try to install, even if you don't have an account admin. Try
        refreshing your browser. It should go away, though you may then see an additional box with a switch to
        preapprove the app yourself before installing.
      </p>
      <h3>I've been invited to the app but nothing happens when I click View</h3>
      <p>You may have an older version of the Zoom application. Try checking for updates as described above.</p>
      <h3>I looked for the Apps button but I don't see it.</h3>
      <p>You may have an older version of the Zoom application. Try checking for updates as described above.</p>
      <h2>Using Miter in Zoom</h2>
      <ol>
        <li>
          Hover over your Zoom meeting window and <strong>click the Apps button</strong> at the bottom:
          <StaticImage
            src="../../../images/zoom-docs/apps-button.png"
            alt="Zoom meeting with Apps button"
            className="Shadowed"
          />
        </li>
        <li>
          A list of your installed apps will appear. Click on Miter and it will appear alongside your video, like this:
          <StaticImage
            className="Shadowed"
            src="../../../images/zoom-docs/apps-sidebar.png"
            alt="Miter visible in a Zoom meeting window"
          />
        </li>
        <li>
          Use Miter's initial screen to <strong>establish a goal and topics</strong> for your meeting. Then click Start.
        </li>
        <li>
          <strong>Take notes collaboratively</strong> with your team in Miter's chat-like notes box.
        </li>
        <li>
          Hover over any note and use the menu that appears to <strong>mark it as an outcome</strong>: a decision, an
          action item, or a starred note.
        </li>
        <li>
          When you're wrapping up, click the Finish button to preview your summary—you'll see everything you marked as
          an outcome there and have the opportunity to make adjustments.
        </li>
        <li>
          After the meeting, <strong>we'll email the summary to everyone</strong> who joined the Zoom or was on the
          calendar invite.
        </li>
      </ol>

      <h2>Uninstalling</h2>
      <ol role="list">
        <li>
          Visit the <a href="http://marketplace.zoom.us">Zoom App Marketplace</a> and sign in.
        </li>
        <li>
          Click <strong>Manage</strong> in the upper right corner of the screen.
        </li>
        <li>
          Click <strong>Installed Apps</strong> on the left to see all your installed Zoom apps. If there are a lot, you
          can search for Miter.
        </li>
        <li>
          Click the <strong>Uninstall</strong> button next to Miter.
        </li>
      </ol>
      <p>
        This will revoke Miter's access to Zoom and remove the Miter app from your Zoom apps on all devices. Don't
        worry:&nbsp;we won't delete your meeting summaries or other meeting data, nor will it affect your connection
        between Miter and Google Calendar if you've set that up. If you <em>want</em> us to delete all your data,{' '}
        <a href="https://miter.co/contact">contact us</a>.
      </p>
    </ProseModule>
  </Layout>
);
